import React, { useState, useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import CustomImage from "@/components/CustomImage";
import { useRouter } from "next/navigation";
import { addItem, removeItem } from "@/store/products/cartSlice";
import { useDispatch } from "react-redux";
import Link from "next/link";
import { useSingleProductAPI } from "@/api/products";
import { useConvertUsdToNaira } from "@/hooks/useConvertNairaToUsd";
import { toast } from "react-toastify";

interface CartItemProps {
  id: number;
  name: string;
  price: number;
  initialAmount: number;
  originalAmount: number;
  amount: number;
  imageUrl?: string;
  quantity?: number;
  productImage?: string;
  accountNumber?: string;
  currency?: string;
}

interface EachProductProps {
  item: CartItemProps;
}

const EachProduct: React.FC<EachProductProps> = ({ item }) => {
  const [quantity, setQuantity] = useState<number>(1);
  const [amount, setAmount] = useState<number>(item?.amount || 0);
  const cartFull = useSelector((state: RootState) => state.cart.cartFull);

  useEffect(() => {
    if (item.quantity) {
      setQuantity(item?.quantity || 1);
    }
  }, [item]);
  const dispatch = useDispatch();

  const incrementQuantity = () => {
    if (cartFull) {
      toast.error("Maximum cart limit reached!");
      return;
    }

    const newQuantity = quantity + 1;
    const newAmount = item?.initialAmount * newQuantity;
    setQuantity(newQuantity);
    setAmount(newAmount);

    dispatch(
      addItem({
        id: item?.id,
        name: item?.name,
        amount: newAmount,
        quantity: newQuantity,
      })
    );
  };

  const decrementQuantity = () => {
    const newQuantity = quantity - 1;
    const newAmount = item?.initialAmount * newQuantity;
    setQuantity(newQuantity);
    setAmount(newAmount);

    if (quantity > 0) {
      dispatch(
        addItem({
          id: item?.id,
          name: item?.name,
          amount: newAmount,
          quantity: newQuantity,
        })
      );
    }
    if (quantity <= 1) {
      dispatch(removeItem({ id: item?.id }));
    }
  };

  return (
    <li className="cartItemCollection my-3">
      <Link href={`/products/${item?.id}`} style={{ textDecoration: "none" }}>
        <CustomImage
          fullSrc={item.productImage || "/images/indexPage/eneba.svg"}
        />
      </Link>
      <div className="cartProductInfo ">
        <Link href={`/products/${item?.id}`} style={{ textDecoration: "none" }}>
          <small className="text-bold d-block">
            {item?.name.slice(0, 12)}
            {item?.name?.length >= 12 && "..."}
          </small>
        </Link>
        <small style={{ textTransform: "uppercase" }}>
          {item.currency}
          {item.originalAmount}
        </small>
      </div>
      <div className="cartProductQuantity  cartOption">
        <button
          className="btn btn-sm"
          onClick={decrementQuantity}
          type="button"
        >
          {"-"}
        </button>
        <input
          className="form-control border-0 shadow-none"
          style={{ width: "30px" }}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
          value={quantity}
          type="text"
          inputMode="numeric"
        />
        <button
          className="btn btn-sm"
          onClick={incrementQuantity}
          type="button"
        >
          {"+"}
        </button>
      </div>
    </li>
  );
};

const DropdownCart = ({ toggleCart }: any) => {
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [isMounted, setIsMounted] = useState<boolean>(true);
  const router = useRouter();

  const { data } = useSingleProductAPI(cartItems?.[0]?.id);
  const product = data?.data?.data;

  useEffect(() => {
    const handleRouteChange = () => {
      if (window.location.pathname === "/payment/checkout") {
        setIsMounted(false);
      }
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  if (!isMounted) return null;

  return (
    <div className={`cartDropDown shadow ${cartItems?.length ? "" : "d-none"}`}>
      <ul className={`p-3`} style={{ display: "inline" }}>
        {cartItems
          ?.slice(0, 10)
          ?.map((item: CartItemProps, key: React.Key | null | undefined) => (
            <EachProduct item={item} key={key} />
          ))}

        <li className="d-flex justify-content-around">
          <button
            onClick={toggleCart}
            className="btn primary-bg shadow text-white btn-sm"
            type="button"
          >
            Keep Shopping
          </button>
          <Link
            className="btn muted-bg text-white shadow px-4 btn-sm"
            href="/payment/checkout"
            onClick={() => setIsMounted(false)}
            type="button"
          >
            Checkout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default DropdownCart;
