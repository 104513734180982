import React, { useEffect, useState } from "react";
import { useParams, useRouter } from "next/navigation";
import Link from "next/link";

const CategoryList = ({
  categories,
  close,
}: {
  categories: any[];
  close: any;
}) => {
  const router = useRouter();
  const { categoryId, subCategoryId } = useParams();
  const [openDropdown, setOpenDropdown] = useState<string | null>(null); // Track which category's dropdown is open

  function goto(link: string) {
    close(false);
    router.push(link);
  }
  // Toggle the dropdown for a specific category
  const toggleDropdown = (id: string) => {
    setOpenDropdown(openDropdown === id ? null : id); // Close if already open, otherwise open the dropdown
  };

  useEffect(() => {
    if (categoryId) {
      toggleDropdown(categoryId as string);
    }
  }, [categoryId]);

  return (
    <ul>
      {categories
        ?.filter((item: any) => item.count !== 0) // Only show categories with items
        .map((item: any) => (
          <li key={item.id} className="nav-link">
            {/* Main Category Link */}
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  onClick={() => goto(`/categories/${item.id}`)}
                  className={`nav-link ${
                    item.id === categoryId ? "activeLinkTwo" : ""
                  }`}
                  aria-current="page"
                >
                  {item.category}
                </button>

                <button
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    fontSize: "12px",
                  }}
                  onClick={() => toggleDropdown(item.id)}
                >
                  ▼
                </button>
              </div>

              {/* Dropdown Menu for Subcategories */}
              {item.subCategories && openDropdown === item.id && (
                <ul
                  style={{
                    paddingTop: 0,
                  }}
                >
                  {item.subCategories.map((sub: any) => (
                    <li key={sub.id}>
                      <button
                        onClick={() => goto(`/categories/${item.id}/${sub.id}`)}
                        style={{
                          fontSize: "15px",
                        }}
                        className={
                          sub.id === subCategoryId
                            ? " nav-link activeLinkTwo activeSubcategory"
                            : "nav-link"
                        }
                      >
                        {sub.category}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
        ))}
    </ul>
  );
};

export default CategoryList;
