import { cancelOrderAPI } from "@/api/orders";
import { createSlice } from "@reduxjs/toolkit";
import { useRouter } from "next/navigation";
import { toast } from "react-toastify";

interface CartItem {
  id: number;
  name: string;
  price: number;
  initialAmount: number;
  originalAmount: number;
  amount: number;
  quantity?: number;
  token?: number;
  currency: string;
}

interface CartState {
  items: CartItem[];
  totalAmount: number;
  totalItem: number;
  cartFull: boolean;
  cartFullError: boolean;
  currencyType?: string;
}

interface PayloadAction {
  payload: {
    id: number;
    name: string;
    initialAmount: number;
    originalAmount: number;
    price: number;
    amount: number;
    productImage?: string;
    quantity?: number;
    token?: number;
    currency: string;
  };
}

const broadcastCartUpdate = () => {
  localStorage.setItem("cart_update", Date.now().toString());
};

const loadState = (): CartState => {
  if (typeof window === "undefined") {
    return {
      items: [],
      totalAmount: 0,
      totalItem: 0,
      cartFull: false,
      cartFullError: false,
    };
  }

  try {
    const serializedState = localStorage.getItem("cart");
    if (serializedState === null) {
      return {
        items: [],
        totalAmount: 0,
        totalItem: 0,
        cartFull: false,
        cartFullError: false,
      };
    }

    const { state, timestamp }: { state: CartState; timestamp: number } =
      JSON.parse(serializedState);
    const orderTimestamp = parseInt(
      localStorage.getItem("orderIdTime") || "0",
      10
    );
    const orderCurrentTime = Date.now();

    if (orderCurrentTime - orderTimestamp >= 5 * 60 * 60 * 1000) {
      const order = JSON.parse(localStorage.getItem("orderId") || "null");
      if (order?.id) {
        cancelOrderAPI(order.id)
          .then(() => {
            localStorage.removeItem("orderId");
            localStorage.removeItem("orderIdTime");
          })
          .catch((error) => {
            console.error("Error canceling order:", error);
          });
      }
    }

    const currentTime = new Date().getTime();
    if (currentTime - timestamp > 5 * 60 * 60 * 1000) {
      const order = JSON.parse(localStorage.getItem("orderId") || "null");
      if (order?.id) {
        cancelOrderAPI(order.id)
          .then(() => {
            localStorage.removeItem("orderId");
            localStorage.removeItem("orderIdTime");
          })
          .catch((error) => {
            console.error("Error canceling order:", error);
          });
      }
      return {
        items: [],
        totalAmount: 0,
        totalItem: 0,
        cartFull: false,
        cartFullError: false,
      };
    }

    return state;
  } catch (e) {
    console.warn("Could not load cart state from localStorage", e);
    return {
      items: [],
      totalAmount: 0,
      totalItem: 0,
      cartFull: false,
      cartFullError: false,
    };
  }
};

const saveState = (state: CartState) => {
  try {
    const serializedState = JSON.stringify({
      state,
      timestamp: new Date().getTime(),
    });
    localStorage.setItem("cart", serializedState);
    broadcastCartUpdate();
  } catch (e) {
    console.warn("Could not save cart state to localStorage", e);
  }
};

const initialState: CartState = loadState();

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state: CartState, action: PayloadAction) => {
      const existingItem = state.items.find(
        (item: CartItem) => item.id === action.payload.id
      );
      const prevQuantity = existingItem?.quantity || 0;

      const quantity =
        action.payload.quantity && action.payload.quantity < 1
          ? 1
          : action.payload.quantity || 1;

      const totalQuantity = state.items.reduce(
        (total, item) => total + (item.quantity || 0),
        0
      );

      state.cartFullError = totalQuantity - prevQuantity + quantity > 5;
      if (totalQuantity - (existingItem?.quantity || 0) + quantity > 5) {
        toast.error("Maximum cart limit reached!");
        return;
      }

      if (existingItem) {
        existingItem.amount = action.payload.amount;
        existingItem.quantity = quantity;
      } else {
        state.items.push({ ...action.payload, quantity });
        state.totalItem += 1;
      }

      state.totalAmount = state.items.reduce(
        (total, item) => total + item.originalAmount * item.amount,
        0
      );

      state.cartFull = totalQuantity - prevQuantity + quantity >= 5;

      saveState(state);
    },
    removeItem: (state: CartState, action: PayloadAction) => {
      const existingItem = state.items.find(
        (item: CartItem) => item.id === action.payload.id
      );
      if (existingItem) {
        state.items = state.items.filter(
          (item) => item.id !== action.payload.id
        );
        state.totalItem -= 1;
      }

      const totalQuantity = state.items.reduce(
        (total, item) => total + (item.quantity || 0),
        0
      );
      state.cartFull = totalQuantity >= 5;
      state.cartFullError = totalQuantity > 5;
      saveState(state);
    },
    clearCart: (state: CartState) => {
      state.items = [];
      state.totalAmount = 0;
      state.totalItem = 0;
      state.cartFull = false;
      state.cartFullError = false;
      saveState(state);
    },
  },
});

export const { addItem, removeItem, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
