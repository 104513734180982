"use client";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useRef, useState, useCallback } from "react";
import { RiCloseLargeLine } from "react-icons/ri";
import { navLinks, navLinks2 } from "@/utils/dummy";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import DropdownCart from "@/components/categoriesPageComponents/DropdownCart";
import { useParams, usePathname, useRouter } from "next/navigation";
import { useLocationAPI, useIpAddressAPI } from "@/api/products";
import { useCategoriesAPI } from "@/api/categories";
import Select from "react-select";
import countryList from "react-select-country-list";
import ReactCountryFlag from "react-country-flag";
import { setLocation } from "@/store/location/locationSlice";
import Search from "@/components/Search";
import CategoryList from "@/components/CategoryList";
// import countryList from 'country-list';

type CountryOption = {
  value: string;
  label: string;
};

function Header() {
  const router = useRouter();

  const { categoryId } = useParams();

  const dispatch = useDispatch();
  const location = useSelector((state: RootState) => state.location);
  const { data: ipAddress } = useIpAddressAPI();
  const { data: locationData } = useLocationAPI(ipAddress);
  const fullSrc = `https://flagcdn.com/48x36/${
    locationData?.country?.toLowerCase() ?? "ng"
  }.png`;
  const { data: categoryData, isLoading: loadingCategory }: any =
    useCategoriesAPI(location?.country);
  const categories = categoryData?.data;
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [nav, setNav] = useState<boolean>(false);
  const totalItem = useSelector((state: RootState) => state.cart.totalItem);
  const pathname = usePathname();
  const path = "/payment/checkout";
  const options = countryList().getData();
  const getCountryName = location?.countryName || locationData?.countryName;
  const defaultCountry = options.find(
    (option: { label: string }) => option.label === getCountryName
  );

  const MANUAL_CHANGE_TIME_KEY = "manualChangeTimestamp"; // Key for localStorage
  const TIME_LIMIT_MS = 90 * 60 * 1000; // 90 minutes in milliseconds

  const revertToIPLocation = () => {
    localStorage.removeItem(MANUAL_CHANGE_TIME_KEY); // Clear the manual change timestamp
    localStorage.setItem("isManualChange", "false");
  };
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(
    defaultCountry || null
  );

  const handleChange = (selectedOption: CountryOption | null) => {
    setSelectedCountry(selectedOption);
    dispatch(
      setLocation({
        country: selectedOption?.value,
        countryName: selectedOption?.label,
      })
    );
    setIsOpen(false);

    localStorage.setItem("isManualChange", "true");

    const currentTime = Date.now();
    localStorage.setItem(MANUAL_CHANGE_TIME_KEY, currentTime.toString());

    // Set a timer to revert back to IP-based selection after 1hr 30mins
    setTimeout(() => {
      revertToIPLocation();
    }, TIME_LIMIT_MS); // 90 minutes timer
  };

  const [isOpen, setIsOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownCartRef = useRef<HTMLDivElement>(null);

  const customFilter = useCallback((option: any, search: string) => {
    if (!search) return true;

    const searchText = search.toLowerCase();
    const label = option.label.toLowerCase();

    if (label.startsWith(searchText)) {
      return true;
    }
    return false;

    // return label.includes(searchText); disabled for strict alphabetical order
  }, []);

  useEffect(() => {
    setSelectedCountry(defaultCountry);
  }, [defaultCountry]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // useEffect(() => {
  //   const handleClickCartOutside = (event: MouseEvent) => {
  //     if (
  //       dropdownCartRef.current &&
  //       !dropdownCartRef.current.contains(event.target as Node)
  //     ) {
  //       setCartOpen(false);
  //     }
  //   };

  //   if (cartOpen) {
  //     document.addEventListener("mousedown", handleClickCartOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickCartOutside);
  //   } . .

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickCartOutside);
  //   };
  // }, [cartOpen]);

  useEffect(() => {
    const savedTime = localStorage.getItem(MANUAL_CHANGE_TIME_KEY);

    if (savedTime) {
      const currentTime = Date.now();
      const timeDifference = currentTime - parseInt(savedTime);

      if (timeDifference >= TIME_LIMIT_MS) {
        // 90 minutes have passed, revert to IP location
        revertToIPLocation();
      } else {
        const remainingTime = TIME_LIMIT_MS - timeDifference;

        setTimeout(() => {
          revertToIPLocation();
        }, remainingTime);
      }
    }
  }, []);

  return (
    <header>
      {/* Navbar 1 */}
      <div className="d-flex justify-content-end">
        <div className={`linearBorderRight`}></div>
      </div>
      <nav className="navbar navbar-expand-xl bg-transparent nav1">
        <div className="container-fluid align-items-start">
          <Link href="/" className="navbar-brand pt-0 ">
            <Image
              loading={"lazy"}
              src="/images/indexPage/bottomLogo.svg"
              width={194}
              height={51}
              alt="logo"
              className="bottomLogo"
            />
          </Link>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <div ref={dropdownCartRef} className="d-lg-none">
              {pathname !== path &&
              pathname !== "/payment/selectpayment" &&
              pathname !== "/payment/ordercompleted" ? (
                <div onClick={toggleCart} style={{ cursor: "pointer" }}>
                  <Image
                    loading={"lazy"}
                    className="invert"
                    src="/images/indexPage/cart_white.svg"
                    width={40}
                    height={40}
                    alt="logo"
                  />
                  {totalItem ? (
                    <small className="position-absolute notificationBadge translate-middle badge rounded-pill bg-danger">
                      {totalItem}
                      <span className="visually-hidden"></span>
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {/* cart DropDown */}
              {cartOpen && <DropdownCart toggleCart={toggleCart} />}
            </div>
            <button
              className="navbar-toggler shadow-none btn-outline-none border-0"
              type="button"
              onClick={() => setNav(true)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <div className="rightList">
              <Search />
              {/* <div ref={dropdownRef}>
                <div
                  onClick={toggleDropdown}
                  style={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    marginTop: "-10px",
                    cursor: "pointer",
                  }}
                >
                  <ReactCountryFlag
                    countryCode={selectedCountry?.value || ""}
                    svg
                    style={{
                      width: "20px",
                      height: "15px",
                      marginRight: "10px",
                      marginLeft: "20px",
                    }}
                  />
                  <span>{selectedCountry?.label}</span>
                </div>
                {isOpen && (
                  <div className="dropdownMenu">
                    <div className="custom-flex">
                      <span>Select Country</span>
                      <Image
                        onClick={toggleDropdown}
                        loading={"lazy"}
                        src="/images/indexPage/close-icon.png"
                        width={20}
                        height={20}
                        alt="close"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <Select
                      className="select-country"
                      options={options}
                      value={selectedCountry}
                      onChange={handleChange}
                      placeholder="Select a country"
                      filterOption={customFilter}
                      isSearchable={true}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      formatOptionLabel={(option) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ReactCountryFlag
                            countryCode={option.value}
                            svg
                            style={{
                              width: "20px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          />
                          {option.label}
                        </div>
                      )}
                    />
                  </div>
                )}
              </div> */}

              <ul className="p-0">
                {navLinks2?.map((item, key) => (
                  <li
                    key={key}
                    className={` mt-1 mx-3 zoom linkHover link ${
                      key === 0 ? "startList" : " px-2"
                    }`}
                  >
                    <Image
                      loading={"lazy"}
                      src={item?.imageLink !== null ? item?.imageLink : fullSrc}
                      width={item?.width}
                      height={item?.height}
                      alt="logo"
                    />
                    <span className={key !== 0 ? "mx-2" : "mx-1"}>
                      {item?.name !== null
                        ? item?.name
                        : locationData?.countryName}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Navbar 2 */}
      <nav className="navbar navbar-expand-xl bg-transparent nav2">
        <div className="container-fluid">
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent2"
          >
            <ul className="navbar-nav me-auto mb-2 mt-4">
              {categories
                // ?.slice(0, 3)
                ?.filter((item: any) => item.count !== 0)
                ?.map((item: any, key: number) => (
                  <li key={key} className="nav-item routeLinks  pb-2">
                    <Link
                      href={`/categories/${item?.id}`}
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                      }}
                      className={`nav-link ${
                        item?.id === categoryId ? "activeLink " : ""
                      }`}
                      aria-current="page"
                    >
                      {item?.category}
                    </Link>
                  </li>
                ))}
            </ul>

            <div ref={dropdownCartRef}>
              {pathname !== path &&
              pathname !== "/payment/selectpayment" &&
              pathname !== "/payment/ordercompleted" ? (
                <div
                  onClick={toggleCart}
                  style={{ cursor: "pointer", marginRight: "30px" }}
                >
                  <Image
                    loading={"lazy"}
                    src="/images/indexPage/cart.svg"
                    width={40}
                    height={40}
                    alt="logo"
                  />
                  {totalItem ? (
                    <small className="position-absolute notificationBadge translate-middle badge rounded-pill bg-danger">
                      {totalItem}
                      <span className="visually-hidden"></span>
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {/* cart DropDown */}
              {cartOpen && <DropdownCart toggleCart={toggleCart} />}

              {/* <button className="btn shadow-0 border-0" type="button">
                <Image
                 loading={'lazy'}

                  src="/images/indexPage/wallet.svg"
                  width={40}
                  height={40}
                  alt="logo"
                />
              </button>  */}

              {/* <button className="btn btn-danger login shadow-sm" type="button">
                Login
              </button>
              <button className="btn login bg-muted shadow-sm border-0" type="button">
                Signup
              </button> */}
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      {nav && (
        <div className="mobileNav d-xl-none">
          <button
            onClick={() => setNav(false)}
            className="btn shadow-none btn-outline-none border-0"
            type="button"
          >
            <RiCloseLargeLine className="text-white" fontSize={25} />
          </button>

          <ul>
            <CategoryList categories={categories} close={setNav} />
            {/* <li className="r-5">
              <button className="btn btnNavLogBg w-100 shadow" type="button">
                Login
              </button>
            </li>
            <li>
              <button
                className="btn login w-100 bg-muted shadow border-0"
                type="button"
              >
                Signup
              </button>
            </li> */}
          </ul>
          {/* <Select
            className="select-country"
            options={options}
            value={selectedCountry}
            onChange={handleChange}
            placeholder="Select a country"
            filterOption={customFilter}
            isSearchable={true}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            formatOptionLabel={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <ReactCountryFlag
                  countryCode={option.value}
                  svg
                  style={{
                    width: "20px",
                    height: "15px",
                    marginRight: "10px",
                  }}
                />
                {option.label}
              </div>
            )}
          />

          <div style={{ display: "flex", gap: 2 }}>
            <ReactCountryFlag
              countryCode={selectedCountry?.value || ""}
              svg
              style={{
                width: "20px",
                height: "15px",
                marginRight: "10px",
              }}
            />
            <span>{selectedCountry?.label}</span>
          </div> */}
        </div>
      )}

      {/* Linear Border */}
      <div className="d-flex justify-content-start linearBorderLeftContainer d-md-block position-relative">
        <div className={`linearBorderLeft`}></div>
      </div>
    </header>
  );
}

export default Header;
